
import { Component, Vue, Prop } from 'vue-property-decorator'
import { BreadCrumb } from '../models/BreadCrumb'

@Component({})
export default class BreadCrumbs extends Vue {
  get crumbs() {
    let pathArray = this.$route.path.split('/')
    pathArray.shift()
    let breadcrumbs: BreadCrumb[] = []
    breadcrumbs.push({
      text: 'Home',
      to: '/'
    })
    for (let i = 0; i < pathArray.length; i++) {
      let item = pathArray[i]
      if (item) {
        // remove slug and uppercase first letter each word
        let words = item.split('-').filter((x) => x) // remove empty string
        let textBreadCrumb = words.map((word) => {
          return word[0].toUpperCase() + word.substring(1)
        }).join(' ')

        let toBreadCrumb = ''
        for (let j = 0; j <= i; j++) {
          toBreadCrumb += `/${pathArray[j]}`
        }

        let breadCrumb = {
          text: decodeURIComponent(textBreadCrumb),
          to: toBreadCrumb,
          exact: true
        }
        // if more than 15 word truncate to 10 word
        breadCrumb.text =
          breadCrumb.text.split(' ').length > 15
            ? breadCrumb.text.split(' ').splice(0, 10).join(' ')
            : breadCrumb.text
        breadcrumbs.push(breadCrumb)
      }
    }
    return breadcrumbs
  }
}
